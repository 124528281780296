import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import * as Yup from 'yup';
import { Formik } from 'formik';

import {
  Card
} from "react-bootstrap";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import { authServices } from '../../services/authServices';

const ResetPassword = () => {
  const [disabled, setDisabled] = useState(false);
  const [mailSend, setMailSend] = useState(false);
  return(
    <React.Fragment>
      <div className="text-center mt-4">
        {mailSend?
          <React.Fragment>
            <h1 className="h2">Email enviado</h1>
            <p className="lead">Revisa tu bandeja de entrada.</p>
          </React.Fragment>
        :
          <React.Fragment>
            <h1 className="h2">Reestablecer contraseña</h1>
            <p className="lead">Ingresa tu email para reestablecer tu contraseña.</p>
          </React.Fragment>
        }
      </div>
      <Card>
        <Card.Body>
          <div className="m-sm-4">
          {mailSend?'':
            <div>
              <Formik
                initialValues={{
                  email: ''
                }}
                validationSchema={Yup.object().shape({
                  email: Yup.string().email('El email no es valido').required('El email es requerido')
                })}
                onSubmit={(values) => {
                  setDisabled(true);
                  authServices.recoverPassword(values)
                    .then(response => {
                      if (response.status === 201) {
                        setMailSend(true);
                      }
                    })
                    .catch(function (error) {
                    }).then(function () {
                      setDisabled(false);
                    });
                }}
              >
                {({
                  errors,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  touched,
                  values
                }) => (
                  <form onSubmit={handleSubmit}>
                    <Box
                      mt={3}
                      mb={1}
                    >
                    </Box>
                    <TextField
                      autoFocus={true}
                      error={Boolean(touched.email && errors.email)}
                      fullWidth
                      helperText={touched.email && errors.email}
                      label="Email"
                      margin="normal"
                      name="email"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="text"
                      value={values.email}
                      variant="outlined"
                    />
                    <div className="block_ctrl text-center my-2">
                      <Button
                        color="primary"
                        disabled={disabled}
                        fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                      >
                        ENVIAR
                      </Button>
                    </div>
                    <div className="block_ctrl text-center">ó</div>
                    <Typography
                      color="textSecondary"
                      variant="body1"
                      align="center"
                    >
                      <Link
                        component={RouterLink}
                        to="/login"
                        variant="p"
                      >
                        Inicia sesión
                      </Link>
                    </Typography>
                  </form>
                )}
              </Formik>
            </div>
          }
          </div>
        </Card.Body>
      </Card>
    </React.Fragment>
  );

}

export default ResetPassword;
