import React, { useState, useEffect } from "react";
import { Link as RouterLink, useNavigate, useParams } from "react-router-dom";

import * as Yup from "yup";
import { Formik } from "formik";

import { Card } from "react-bootstrap";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Input from '@mui/material/Input';

import { authServices } from "../../services/authServices";

const MakePassword = () => {
  
  const { code } = useParams();
  let navigate = useNavigate();
  const [disabled, setDisabled] = useState(false);

  const [showPassword01, setShowPassword01] = useState(false);
  const [showPassword02, setShowPassword02] = useState(false);
  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const [codeOk, setCodeOk] = useState(false);

  function verifyCode(code){
    authServices.verifyCodeRecoverPassword(code)
    .then(response => {
      if (response.status===200) {
        setCodeOk(true);
      }
    })
    .catch(function (error) {
      navigate("/login");
    })
  }

  useEffect(() => {
    verifyCode(code);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return(
    <React.Fragment>
      <div className="text-center mt-4">
        <h1 className="h2">Reestablecer contraseña</h1>
        <p className="lead">Ingresa una nueva contraseña.</p>
      </div>
      <Card>
        <Card.Body>
          <div className="m-sm-4">
            {codeOk?
            <div>
              <Formik
                initialValues={{
                  new_password: '',
                  repeat_password: ''
                }}
                validationSchema={Yup.object().shape({
                  new_password: Yup.string().min(8, "La contraseña debe tener al menos 8 caracteres").required('La contraseña es requerida')
                  .matches(
                    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/,
                    "Debe contener 8 caracteres, uno en mayúscula, uno en minúscula, un número y un caracter especial"
                  ),
                  repeat_password: Yup.string()
                  .min(8, "La contraseña debe tener al menos 8 caracteres")
                  .required("La contraseña es requerida")
                  .oneOf([Yup.ref('new_password'), null], 'Las contrraseñas no coinciden')
                  .matches(
                    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/,
                    "Debe contener 8 caracteres, uno en mayúscula, uno en minúscula, un número y un caracter especial"
                  )
                })}
                onSubmit={(values) => {
                  setDisabled(true);
                  authServices.savePassword(values, code)
                    .then(response => {
                      if (response.status===200) {
                        navigate("/login");
                      }
                    })
                    .catch(function (error) {
                    }).then(function () {
                      setDisabled(false);
                    });
                }}
              >
                {({
                  errors,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  touched,
                  values
                }) => (
                  <form onSubmit={handleSubmit}>
                    <Box
                      mt={3}
                      mb={1}
                    >
                    </Box>
                    <FormControl variant="standard" error={Boolean(touched.new_password && errors.new_password)} margin="normal" fullWidth>
                      <InputLabel htmlFor="standard-adornment-password">Nueva Contraseña</InputLabel>
                      <Input
                        name="new_password"
                        type={showPassword01 ? 'text' : 'password'}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.new_password}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() => setShowPassword01(!showPassword01) }
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {showPassword01 ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          </InputAdornment>
                        }
                        label="Password"
                      />
                      {Boolean(touched.new_password && errors.new_password) ?
                        <FormHelperText id="component-error-text">{errors.new_password}</FormHelperText>
                      :''}
                    </FormControl>
                    <FormControl variant="standard" error={Boolean(touched.repeat_password && errors.repeat_password)} margin="normal" fullWidth>
                      <InputLabel htmlFor="standard-adornment-password">Repite la Nueva Contraseña</InputLabel>
                      <Input
                        name="repeat_password"
                        type={showPassword02 ? 'text' : 'password'}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.repeat_password}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() => setShowPassword02(!showPassword02) }
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {showPassword02 ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          </InputAdornment>
                        }
                        label="Password"
                      />
                      {Boolean(touched.repeat_password && errors.repeat_password) ?
                        <FormHelperText id="component-error-text">{errors.repeat_password}</FormHelperText>
                      :''}
                    </FormControl>
                    <div className="block_ctrl text-center my-2">
                      <Button
                        color="primary"
                        disabled={disabled}
                        fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                      >
                        GUARDAR
                      </Button>
                    </div>
                    <div className="block_ctrl text-center">ó</div>
                    <Typography
                      color="textSecondary"
                      variant="body1"
                      align="center"
                    >
                      <Link
                        component={RouterLink}
                        to="/login"
                        variant="p"
                      >
                        Inicia sesión
                      </Link>
                    </Typography>
                  </form>
                )}
              </Formik>
            </div>
            :''}
          </div>
        </Card.Body>
      </Card>
    </React.Fragment>
  );

}

export default MakePassword;
