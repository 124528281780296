import React, { useState } from 'react';
import {
  Card,
  Col,
  Container,
  ListGroup,
  ListGroupItem,
  Row
} from "react-bootstrap";

import TextField from '@mui/material/TextField';
import ButtonMaterial from '@mui/material/Button';
import * as Yup from 'yup';
import { Formik } from 'formik';

import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Input from '@mui/material/Input';

import { logedServices } from '../../services/logedServices';

const Settings = () => {

  const [section, setSection] = useState(1);
  const [disabled, setDisabled] = useState(false);

  const [showPassword01, setShowPassword01] = useState(false);
  const [showPassword02, setShowPassword02] = useState(false);
  const [showPassword03, setShowPassword03] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const Navigation = () => {
    return(
      <Card>
        <Card.Header>
          <Card.Title tag="h5" className="mb-0">
            Perfil
          </Card.Title>
        </Card.Header>
        <ListGroup flush>
          <ListGroupItem tag="a" href="#" onClick={() => setSection(1)} action active={section===1?true:false}>
            Cuenta
          </ListGroupItem>
          <ListGroupItem tag="a" href="#" onClick={() => setSection(2)} action active={section===2?true:false}>
            Contraseña
          </ListGroupItem>
        </ListGroup>
      </Card>
    );
  }

  const PublicInfo = () => {
    return(
      <Card>
        <Card.Header>
          <Card.Title tag="h5" className="mb-0">
            Información de usuario
          </Card.Title>
        </Card.Header>
        <Card.Body>
          <Row>
            <Col>
              <Formik
                initialValues={{
                  name: JSON.parse(localStorage.getItem('user_data')).name,
                  email: JSON.parse(localStorage.getItem('user_data')).email
                }}
                validationSchema={Yup.object().shape({
                  name: Yup.string().required('El nombre es requerido.'),
                  email: Yup.string().email('El email es invalido.').required('El email es requerido.')
                })}
                onSubmit={(values) => {
                  setDisabled(true);
                  logedServices.updateProfile(values)
                    .then(response => {
                    })
                    .catch(function (error) {
                    }).then(function () {
                      setDisabled(false);
                    });
                }}
              >
                {({
                  errors,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  touched,
                  values
                }) => (
                  <form onSubmit={handleSubmit}>
                    <div className="block_ctrl pb-3">
                      <div className="row">
                        <div className="col-12 col-md-6 col-lg-5 col-xl-4">
                          <TextField
                            error={Boolean(touched.name && errors.name)}
                            fullWidth
                            helperText={touched.name && errors.name}
                            label="Nombre"
                            margin="normal"
                            name="name"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            type="text"
                            value={values.name}
                            variant="standard"
                          />
                        </div>
                        <div className="col-12 col-md-6 col-lg-5 col-xl-4">
                          <TextField
                            error={Boolean(touched.email && errors.email)}
                            fullWidth
                            helperText={touched.email && errors.email}
                            label="Email"
                            margin="normal"
                            name="email"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            type="text"
                            value={values.email}
                            variant="standard"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="block_ctrl">
                      <ButtonMaterial variant="outlined" type="submit" disabled={disabled}>
                        Guardar cambios
                      </ButtonMaterial>
                    </div>
                  </form>
                )}
              </Formik>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      )
  };

  return (
    <Container fluid className="p-0">
      <h1 className="h3 mb-3">Configuración</h1>
      <Row>
        <Col md="3" xl="2">
          <Navigation />
        </Col>
        <Col md="9" xl="10">
          {section===1?
            <PublicInfo />
          :''}
          {section===2?
            <Card>
              <Card.Header>
                <Card.Title tag="h5" className="mb-0">
                  Cambio de contraseña
                </Card.Title>
              </Card.Header>
              <Card.Body>
                <Row>
                  <Col>
                    <Formik
                      initialValues={{
                        password: '',
                        new_password: '',
                        repeat_password: ''
                      }}
                      validationSchema={Yup.object().shape({
                        password: Yup.string().required('La contraseña es requerida'),
                        new_password: Yup.string().min(8, "La contraseña debe tener al menos 8 caracteres").required('La contraseña es requerida')
                        .matches(
                          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#%&])(?=.{8,})/,
                          "Debe contener 8 caracteres, uno en mayúscula, uno en minúscula, un número y un caracter especial"
                        ),
                        repeat_password: Yup.string()
                        .min(8, "La contraseña debe tener al menos 8 caracteres")
                        .required("La contraseña es requerida")
                        .oneOf([Yup.ref('new_password'), null], 'Las contrraseñas no coinciden')
                        .matches(
                          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#%&])(?=.{8,})/,
                          "Debe contener 8 caracteres, uno en mayúscula, uno en minúscula, un número y un caracter especial"
                        )
                      })}
                        onSubmit={(values, { resetForm }) => {
                          setDisabled(true);
                          logedServices.updateCurrentPassword(values)
                            .then(response => {
                              if (response.status === 200) {
                                setIsSubmitted(true);
                                resetForm(); // Restablecer los valores del formulario
                              }
                            })
                            .catch(function (error) {
                            })
                            .finally(function () {
                              setDisabled(false);
                            });
                        }}
                    >
                      {({
                        errors,
                        handleBlur,
                        handleChange,
                        handleSubmit,
                        touched,
                        values
                      }) => (
                        <form onSubmit={handleSubmit}>
                          <div className="block_ctrl pb-3">
                            <div className="row">
                              <div className="col-12 col-md-6 col-lg-5 col-xl-4">
                                <FormControl variant="standard" error={Boolean(touched.password && errors.password)} margin="normal" fullWidth>
                                  <InputLabel htmlFor="standard-adornment-password">Contraseña</InputLabel>
                                  <Input
                                    name="password"
                                    type={showPassword01 ? 'text' : 'password'}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.password}
                                    endAdornment={
                                      <InputAdornment position="end">
                                        <IconButton
                                          aria-label="toggle password visibility"
                                          onClick={() => setShowPassword01(!showPassword01) }
                                          onMouseDown={handleMouseDownPassword}
                                          edge="end"
                                        >
                                          {showPassword01 ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                      </InputAdornment>
                                    }
                                    label="Password"
                                  />
                                  {Boolean(touched.password && errors.password) ?
                                    <FormHelperText id="component-error-text">{errors.password}</FormHelperText>
                                  :''}
                                </FormControl>
                              </div>
                              <div className="col-12 col-md-6 col-lg-5 col-xl-4">
                                <FormControl variant="standard" error={Boolean(touched.new_password && errors.new_password)} margin="normal" fullWidth>
                                  <InputLabel htmlFor="standard-adornment-password">Nueva Contraseña</InputLabel>
                                  <Input
                                    name="new_password"
                                    type={showPassword02 ? 'text' : 'password'}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.new_password}
                                    endAdornment={
                                      <InputAdornment position="end">
                                        <IconButton
                                          aria-label="toggle password visibility"
                                          onClick={() => setShowPassword02(!showPassword02) }
                                          onMouseDown={handleMouseDownPassword}
                                          edge="end"
                                        >
                                          {showPassword02 ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                      </InputAdornment>
                                    }
                                    label="Password"
                                  />
                                  {Boolean(touched.new_password && errors.new_password) ?
                                    <FormHelperText id="component-error-text">{errors.new_password}</FormHelperText>
                                  :''}
                                </FormControl>
                              </div>
                              <div className="col-12 col-md-6 col-lg-5 col-xl-4">
                                <FormControl variant="standard" error={Boolean(touched.repeat_password && errors.repeat_password)} margin="normal" fullWidth>
                                  <InputLabel htmlFor="standard-adornment-password">Repite la Nueva Contraseña</InputLabel>
                                  <Input
                                    name="repeat_password"
                                    type={showPassword03 ? 'text' : 'password'}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.repeat_password}
                                    endAdornment={
                                      <InputAdornment position="end">
                                        <IconButton
                                          aria-label="toggle password visibility"
                                          onClick={() => setShowPassword03(!showPassword03) }
                                          onMouseDown={handleMouseDownPassword}
                                          edge="end"
                                        >
                                          {showPassword03 ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                      </InputAdornment>
                                    }
                                    label="Password"
                                  />
                                  {Boolean(touched.repeat_password && errors.repeat_password) ?
                                    <FormHelperText id="component-error-text">{errors.repeat_password}</FormHelperText>
                                  :''}
                                </FormControl>
                              </div>
                            </div>
                          </div>
                          <div className="block_ctrl">
                            <ButtonMaterial variant="outlined" type="submit" disabled={disabled}>
                              Guardar cambios
                            </ButtonMaterial>
                          </div>
                        </form>
                      )}
                    </Formik>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          :''}
        </Col>
      </Row>
    </Container>
  );
}

export default Settings;
