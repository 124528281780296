import React from 'react';
import { Dropdown } from "react-bootstrap";
import { Settings, User } from "react-feather";
import { logedServices } from '../../services/logedServices';
import { useNavigate, Link } from 'react-router-dom';
import usersvg from "../../assets/img/avatars/user.svg";

const NavbarUser = () => {

  let navigate = useNavigate();
  const handleLogout = () => {
    logedServices.logoutAndClearLocalStorage()
      .then(() => {
        navigate("/");
      })
      .catch(error => {
        console.log('error===>');
        console.log(error);
      });
  };

  return (
    <Dropdown className="nav-item" align="end">
      <span className="d-inline-block d-sm-none">
        <Dropdown.Toggle as="a" className="nav-link">
          <Settings size={18} className="align-middle" />
        </Dropdown.Toggle>
      </span>
      <span className="d-none d-sm-inline-block">
        <Dropdown.Toggle as="a" className="nav-link">
          <img
            src={usersvg}
            className="avatar img-fluid rounded-circle me-1"
            alt={localStorage.getItem('user_data')!==null && localStorage.getItem('user_data')!==undefined ? JSON.parse(localStorage.getItem('user_data')).name : ''}
          />
          <span className="text-dark">{localStorage.getItem('user_data')!==null && localStorage.getItem('user_data')!==undefined ? JSON.parse(localStorage.getItem('user_data')).name : ''}</span>
        </Dropdown.Toggle>
      </span>
      <Dropdown.Menu drop="end">
        <Dropdown.Item as={Link} to="/admin/perfil">
          <User size={18} className="align-middle me-2" />
          Perfil
        </Dropdown.Item>
        <Dropdown.Divider />
        <Dropdown.Item onClick={() => {handleLogout()}}>Cerrar sesión</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default NavbarUser;
