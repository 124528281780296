import { showToastr, toastrError } from '../utils/toast';
import { axiosApiInstancePublic } from './manageToken'

async function login(values) {
  return axiosApiInstancePublic.post(
    `${process.env.REACT_APP_API_CALLS}login`,
    values
  ).then(response => {
    if (response.status === 201) {
      localStorage.setItem('token', response.data.token);
      localStorage.setItem('user_data', JSON.stringify(response.data.user));
      showToastr('success', 'Correcto.', 'Sesión iniciada.', 2500, true, true, 'top-right');
    }else{
      showToastr('error', 'Email/contraseña incorrectos.', 'Vuelve a intentarlo.', 2500, true, true, 'top-right');
    }
    return response;
  }).catch(function (error) {
    toastrError(error);
    return Promise.reject(error);
  });
}

async function recoverPassword(values) {
  return axiosApiInstancePublic.post(
    `${process.env.REACT_APP_API_CALLS}recoverPassword`,
    values
  ).then(response => {
    if (response.status === 201) {
      showToastr('success', 'Correcto.', 'Revisa tu bandeja de entrada.', 2500, true, true, 'top-right');
    }
    return response;
  }).catch(function (error) {
    toastrError(error);
    return Promise.reject(error);
  });
}

async function verifyCodeRecoverPassword(code) {
  return axiosApiInstancePublic.post(
    `${process.env.REACT_APP_API_CALLS}verifyCode`,
      {
        code: code
      }
  ).then(response => {
    return response;
  }).catch(function (error) {
    toastrError(error);
    return Promise.reject(error);
  });
}

async function savePassword(values, code) {
  return axiosApiInstancePublic.post(
    `${process.env.REACT_APP_API_CALLS}savePassword`,
      {
        code: code,
        ...values
      }
  ).then(response => {
    if (response.status===200) {
      showToastr('success', 'Correcto.', 'Ya puedes acceder con tu nueva contraseña.', 2500, true, true, 'top-right');
    }
    return response;
  }).catch(function (error) {
    toastrError(error);
    return Promise.reject(error);
  });
}

export const authServices = {
  login,
  recoverPassword,
  verifyCodeRecoverPassword,
  savePassword
};