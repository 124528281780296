import axios from 'axios';
import { toastrError } from '../utils/toast';


/*AXIOS AND TOKEN CONFIG*/
export var credentials = axios.create({
    withCredentials: true
});

export const axiosApiInstance = axios.create();
export const axiosApiInstanceFormData = axios.create();
export const axiosApiInstancePublic = axios.create();

// Private access
axiosApiInstance.interceptors.request.use(
  async config => {
    const token_=localStorage.getItem('token');
    config.headers = { 
        'Authorization': `Bearer ${token_}`,
        'Accept': 'application/json'
    }
    return config;
  },
  error => {
    toastrError(error);
  }
);

// Form data
axiosApiInstanceFormData.interceptors.request.use(
  async config => {
    const token_=localStorage.getItem('token');
    config.headers = { 
        'Authorization': `Bearer ${token_}`,
        'Accept': 'application/json',
        'Content-Type': 'multipart/form-data'
    }
    return config;
  },
  error => {
    toastrError(error);
  }
);

// Public access
axiosApiInstancePublic.interceptors.request.use(
  async config => {
    config.headers = { 
        'Accept': 'application/json'
    }
    return config;
  },
  error => {
    toastrError(error);
  }
);