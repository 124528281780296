import React, { useState, useEffect, useCallback } from 'react';
import { 
  Card,
  Container,
  Col,
  InputGroup,
  Button,
  ButtonGroup,
  Form,
  Row,
  Modal
} from "react-bootstrap";
import ButtonMaterial from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Pagination from '@mui/material/Pagination';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import SearchIcon from '@mui/icons-material/Search';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import SendIcon from '@mui/icons-material/Send';
import FilterIcon from '@mui/icons-material/Filter';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { red } from '@mui/material/colors';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table'
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import CurrencyFormat from 'react-currency-format';
import * as Yup from 'yup';
import { setLocale } from 'yup';
import { Formik } from 'formik';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Paper from '@mui/material/Paper';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import ImageUploading from 'react-images-uploading';
import SortableList, { SortableItem } from 'react-easy-sort';
import arrayMove from "array-move";

import { logedServices } from '../../services/logedServices';

function not(a, b) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

const TableRes = () => {

  setLocale({
    mixed: {
      required: 'Este campos es requerido.'
    },
    string: {
      max: 'Valor demasiado largo (máximo ${max} caracteres)'
    }
  })

  const [, updateState] = useState();
  const handleForceupdateMethod = useCallback(() => updateState({}), []);

  const [lastPage, setLastPage] = useState(0);
  const [pageSelected, setPageSelected] = useState(0);
  const [searchState, setSearchState] = useState(null);
  const [paramState, setParamState] = useState('id');
  const [orderState, setOrderState] = useState('ASC');
  const [modelos, setModelos] = useState(null);
  const [desarrollos, setDesarrollos] = useState(null);
  const [imagenes, setImagenes] = useState(null);
  const [imagenesToDelete, setImagenesToDelete] = useState([]);
  const [specs, setSpecs] = useState(null);
  const [specsByModelo, setSpecsByModelo] = useState(null);
  const [disabled, setDisabled] = useState(false);
  const [dataActive, setDataActive] = useState(null);
  const [modalAdd, setModalAdd] = useState(false);
  const toggleModalAdd = () => setModalAdd(!modalAdd);
  const [modalUpdate, setModalUpdate] = useState(false);
  const toggleModalUpdate = () => setModalUpdate(!modalUpdate);
  const [modalAmenidades, setModalAmenidades] = useState(false);
  const toggleModalAmenidades = () => setModalAmenidades(!modalAmenidades);
  const [modalImages, setModalImages] = useState(false);
  const toggleModalImages = () => setModalImages(!modalImages);

  /*<---Images upload*/
  const [images, setImages] = React.useState([]);
  const [maxNumberImg, setMaxNumberImg] = React.useState(0);
  const onChange = (imageList, addUpdateIndex) => {
    setImages(imageList);
  };
  /*Images upload--->*/

  const [tipoTerreno, setTipoTerreno] = React.useState(false);

  /*<---TRANSFER LIST*/
  const [checked, setChecked] = React.useState([]);
  const [left, setLeft] = React.useState(null);
  const [right, setRight] = React.useState(null);

  let patternTwoDigisAfterComma = /^\d+(\.\d{0,2})?$/;
  const commonStringValidator = Yup
    .number()
    .positive()
    .test(
      "is-decimal",
      "El precio debe de contener máximo 2 decimales después del  .",
      (val: any) => {
        if (val !== undefined) {
          return patternTwoDigisAfterComma.test(val);
        }
        return true;
      }
    )
    .required();

  const validaGeneral=Yup.object().shape({
    desarrollo_id: Yup.string().required(),
    clave_interna: Yup.string().required(),
    titulo_anuncio: Yup.string().required(),
    precio: commonStringValidator,
    descripcion: Yup.string().required().max(2000),
    mostrar_precio: Yup.string().required(),
    tipo_propiedad: Yup.string().required(),
    no_dormitorios: Yup.number().required(),
    no_banos: Yup.number().required(),
    no_medios_banos: Yup.number().required(),
    no_estacionamientos: Yup.number().required(),
    construccion_m2: Yup.string().required(),
    terreno_m2: Yup.string().required(),
    no_piso: Yup.string().required(),
    total_pisos: Yup.string().required(),
    mantenimiento_mensual: Yup.string().required(),
    estatus: Yup.string().required()
  });
  const validaTerreno = Yup.object().shape({
    desarrollo_id: Yup.string().required(),
    clave_interna: Yup.string().required(),
    titulo_anuncio: Yup.string().required(),
    precio: commonStringValidator,
    descripcion: Yup.string().required().max(2000),
    mostrar_precio: Yup.string().required(),
    tipo_propiedad: Yup.string().required(),
    terreno_m2: Yup.string().required(),
    largo_terreno: Yup.string().required(),
    frente_terreno: Yup.string().required(),
    uso_suelo: Yup.string().required(),
    estatus: Yup.string().required()
  });

  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const handleAllRight = () => {
    setRight(right.concat(left));
    setLeft([]);
  };

  const handleCheckedRight = () => {
    setRight(right.concat(leftChecked));
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked));
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
  };

  const handleAllLeft = () => {
    setLeft(left.concat(right));
    setRight([]);
  };

  function customList(items){
    if (items!==null && items.length>0) {
      return(
        <Paper sx={{ width: 200, height: 230, overflow: 'auto' }}>
          <List dense component="div" role="list">
            {items.map((value) => {
              const labelId = `transfer-list-item-${value}-label`;
              return (
                <ListItem
                  key={value.id}
                  role="listitem"
                  button
                  onClick={handleToggle(value)}
                >
                  <ListItemIcon>
                    <Checkbox
                      checked={checked.indexOf(value) !== -1}
                      tabIndex={-1}
                      disableRipple
                      inputProps={{
                        'aria-labelledby': labelId,
                      }}
                    />
                  </ListItemIcon>
                  <ListItemText id={labelId} primary={value.nombre} />
                </ListItem>
              );
            })}
          </List>
        </Paper>
      );
    }else{
      return('');
    }
  }
  /*TRANSFER LIST -->*/

  useEffect(() => {
      getModelos(1,searchState,paramState,orderState);
      getDesarrollos();
  }, []);

  function getModelos(page, search, param, order){
      setDisabled(true);
      setTipoTerreno(false);
      logedServices.getModelos(page, 10, search, param, order)
      .then(response => {
        if (response.status===200) {
          setPageSelected(page);
          setModelos(response.data);
          setLastPage(response.data.meta.last_page);
          setSearchState(search);
          setParamState(param);
          setOrderState(order);
        }
        setDisabled(false);
      })
      .catch(function (error) {
          return error;
      })
  }

  function getDesarrollos(){
      setDisabled(true);
      logedServices.getAllDesarrollos()
      .then(response => {
        if (response.status===200) {
          setDesarrollos(response.data);
        }
        setDisabled(false);
      })
      .catch(function (error) {
          return error;
      })
  }

  /*<---Delete*/
  const confirmDeleteModelo = (id) => {
    confirmAlert({
      title: 'Confirmación',
      message: 'Estás seguro que deseas eliminar el registro?',
      buttons: [
        {
          label: 'Si',
          onClick: () => deleteModelo(id)
        },
        {
          label: 'No',
          onClick: () => {}
        }
      ]
    });
  };

  function deleteModelo(id){
      setDisabled(true);
      logedServices.deleteModelo(id)
      .then(response => {
        if (response.status===204) {
          getModelos(pageSelected, searchState, paramState, orderState);
        }
        setDisabled(false);
      })
      .catch(function (error) {
          return error;
      })
  }
  /*Delete--->*/

  const handleChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
    getModelos(value, searchState, paramState, orderState);
  };

  const handleChangeParam = event => {
    let param_ = event.target.value;
    setParamState(param_);
    getModelos(pageSelected, searchState, param_, orderState);
  };

  const handleChangeOrder = event => {
    let order_ = event.target.value;
    setOrderState(order_);
    getModelos(pageSelected, searchState, paramState, order_);
  };

  function getAmenidadesByModelo(propiedad_id){
      setDisabled(true);
      logedServices.getAmenidadesByModelo(propiedad_id)
      .then(response => {
        if (response.status===200) {
          setLeft(response.data.unused);
          setRight(response.data.used);
        }
        setDisabled(false);
      })
      .catch(function (error) {
          return error;
      })
  }

  function saveAmenidadesPropiedad(){
      setDisabled(true);
      logedServices.saveAmenidadesPropiedad(dataActive.id, right)
      .then(response => {
        if (response.status===200) {
          toggleModalAmenidades();
        }
        setDisabled(false);
      })
      .catch(function (error) {
          return error;
      })
  }

  /*<---Images*/
  function getImagesByModelo(desarrollo_id){
      setDisabled(true);
      logedServices.getImagesByModelo(desarrollo_id)
      .then(response => {
        if (response.status===200) {
          setImagenes(response.data.data);
          setMaxNumberImg(20-response.data.data.length);
        }
        setDisabled(false);
      })
      .catch(function (error) {
          return error;
      })
  }

  function saveImagesModelo(){
    setDisabled(true);
    var formData = new FormData();
    formData.append("reorder", reorder);
    formData.append("imagenes", JSON.stringify(imagenes));
    let c=0;
    images.forEach(function(image) {
      formData.append("image_"+c, image.file);
        c++;
    });
    logedServices.saveImagesModelo(dataActive.id, formData, imagenesToDelete)
    .then(response => {
      if (response.status===200) {
        setImagenes(null);
        setImagenesToDelete([]);
        setImages([]);
        toggleModalImages();
      }
      setDisabled(false);
    })
    .catch(function (error) {
        return error;
    })
  }

  function onImageRemove(idImagenModelo) {
    const newList = imagenes.filter((imagen) => imagen.id !== idImagenModelo);
    setImagenes(newList);
    setImagenesToDelete(current => [...current, idImagenModelo]);
    setMaxNumberImg(maxNumberImg+1);
  }
  /*Images--->*/

  /*<---Sort*/
  const onSortEnd = (oldIndex: number, newIndex: number) => {
    setImagenes((array) => arrayMove(array, oldIndex, newIndex));
    setReorder(true);
  };
  const [reorder, setReorder] = React.useState(false);
  /*Sort---->*/


  /*<---Specs*/
  function getSpecs(){
      setDisabled(true);
      logedServices.getAllSpecs()
      .then(response => {
        if (response.status===200) {
          let obj_specs=[];
          response.data.data.forEach(function(spec) {
            var obj_spec = {
              id: spec.id,
              nombre: spec.nombre,
              clasificacion: spec.clasificacion,
              value: false
            };
            obj_specs.push(obj_spec);
          })
          setSpecs(obj_specs);
        }
        setDisabled(false);
      })
      .catch(function (error) {
          return error;
      })
  }

  function handleChangeCheck(item){
    let new_val = {
      id: item.id,
      nombre: item.nombre,
      clasificacion: item.clasificacion,
      value: !item.value
    };
    let index = specs.indexOf(item);
    let temp_obj=specs;
    temp_obj[index] = new_val;
    setSpecs(temp_obj);
    handleForceupdateMethod();
  }

  const CheckboxSpecs = (props) => {
    let specs_ = props.propSpecs;
    if (specs_!==null && specs_.length>0) {
      var clasif = '';
      var showTitle = true;
      const listCheckbox = specs_.map((spec) => {
          if (clasif===spec.clasificacion) {
            showTitle = false;
          }else{
            showTitle = true;
          }
          clasif = spec.clasificacion;
          return(
            <div className="block_ctrl">
              {showTitle?
                <div className="section_ mt-3 mb-1">
                  <h4><strong>{spec.clasificacion}</strong></h4>
                </div>
              :''}
              <div className="block_ctrl">
                <FormGroup key={spec.id}>
                  <FormControlLabel
                    key={'check_'+spec.id}
                    control={
                      <Checkbox checked={spec.value} onChange={() => {handleChangeCheck(spec)}} inputProps={{ 'aria-label': 'controlled' }} />
                    }
                    label={spec.nombre}
                  />
                </FormGroup>
              </div>
            </div>
          );
          clasif=spec.clasificacion;
        }
      );
      return(
          <div className="list_checkbox_specs">
              {listCheckbox}
          </div>
      );
    }else{
      return('');
    }
  }

  function handleChangeCheckUpd(item){
    let new_val = {
      id: item.id,
      nombre: item.nombre,
      clasificacion: item.clasificacion,
      value: !item.value
    };
    let index = specsByModelo.indexOf(item);
    let temp_obj=specsByModelo;
    temp_obj[index] = new_val;
    setSpecs(temp_obj);
    handleForceupdateMethod();
  }

  const CheckboxSpecsUpd = (props) => {
    let specs_ = props.propSpecs;
    if (specs_!==null && specs_.length>0) {
      var clasif = '';
      var showTitle = true;
      const listCheckbox = specs_.map((spec) => {
          if (clasif===spec.clasificacion) {
            showTitle = false;
          }else{
            showTitle = true;
          }
          clasif = spec.clasificacion;
          return(
            <div className="block_ctrl">
              {showTitle?
                <div className="section_ mt-3 mb-1">
                  <h4><strong>{spec.clasificacion}</strong></h4>
                </div>
              :''}
              <div className="block_ctrl">
                <FormGroup key={spec.id}>
                  <FormControlLabel
                    key={'check_'+spec.id}
                    control={
                      <Checkbox checked={spec.value} onChange={() => {handleChangeCheckUpd(spec)}} inputProps={{ 'aria-label': 'controlled' }} />
                    }
                    label={spec.nombre}
                  />
                </FormGroup>
              </div>
            </div>
          );
          clasif=spec.clasificacion;
        }
      );
      return(
          <div className="list_checkbox_specs">
              {listCheckbox}
          </div>
      );
    }else{
      return('');
    }
  }

  function getSpecsByModelo(modelo_id){
      setDisabled(true);
      logedServices.getSpecsByModelo(modelo_id)
      .then(response => {
        if (response.status===200) {
          setSpecsByModelo(response.data);
        }
        setDisabled(false);
      })
      .catch(function (error) {
          return error;
      })
  }

  /*Specs---->*/
  
  return (
  <React.Fragment> 
    <Card>
      <Card.Body>
      <Row>

          {modelos!==null && modelos.data.length>0 ? 
            <React.Fragment>
                  <Col xs="12" md="6" lg="4" xl="3" className="mb-3">
                    <Formik
                      initialValues={{
                        busqueda: ''
                      }}
                      onSubmit={(values) => {
                        getModelos(1, values.busqueda, paramState, orderState);
                      }}
                    >
                      {({
                        errors,
                        handleBlur,
                        handleChange,
                        handleSubmit,
                        touched,
                        values
                      }) => (
                        <form onSubmit={handleSubmit}>
                          <Form.Label
                            className="visually-hidden"
                            htmlFor="busqueda"
                          >
                            Busqueda
                          </Form.Label>
                          <InputGroup>
                            <Form.Control 
                              id="busqueda"
                              name="busqueda"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.busqueda}
                            />
                            <Button type="submit" disabled={disabled} color="primary">
                              <SearchIcon />
                            </Button>
                          </InputGroup>
                        </form>
                      )}
                    </Formik>
                  </Col>
                  <Col xs="12" md="6" lg="4" xl="3" className="mb-3">
                    <Form.Label
                      className="visually-hidden"
                      htmlFor="campo"
                    >
                      Orden
                    </Form.Label>
                      <Row>
                        <Col>
                          <Form.Select
                            value={paramState}
                            onChange={handleChangeParam}
                          >
                            <option value="id">
                              ID
                            </option>
                            <option value="clave_interna">
                              Clave interna
                            </option>
                            <option value="titulo_anuncio">
                              Titulo
                            </option>
                            <option value="tipo_propiedad">
                              Tipo de propiedad
                            </option>
                            <option value="uso_suelo">
                              Uso de suelo
                            </option>
                            <option value="precio">
                              Precio
                            </option>
                            <option value="descripcion">
                              Descripción
                            </option>
                            <option value="modelos.borrador">
                              Borrador
                            </option>
                          </Form.Select>
                        </Col>
                        <Col>
                          <Form.Select
                            value={orderState}
                            onChange={handleChangeOrder}
                          >
                            <option value="asc">
                              ASC
                            </option>
                            <option value="desc">
                              DESC
                            </option>
                          </Form.Select>
                        </Col>
                      </Row>
                  </Col>
                  </React.Fragment>
                  : ''}
                  <Col xs="12" md="6" lg="4" xl="6" className="mb-3 text-end">                  
                    <ButtonMaterial disabled={disabled} variant="contained" endIcon={<AddIcon />} onClick={() => {getSpecs();toggleModalAdd();}}>
                      Agregar
                    </ButtonMaterial>
                  </Col>
                  {modelos!==null && modelos.data.length>0 ?
                  <Col xs="12" className="mb-3">
                    <Table className="">
                      <Thead>
                        <Tr>
                          <Th></Th>
                          <Th>CLAVE INTERNA</Th>
                          <Th>TITULO</Th>
                          <Th>TIPO DE PROPIEDAD</Th>
                          <Th>PRECIO</Th>
                          <Th>DESCRIPCIÓN</Th>
                          <Th></Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {modelos.data.map((modelo) => (
                          <Tr key={modelo.id}>
                            <Td>{modelo.id}</Td>
                            <Td>{modelo.clave_interna}</Td>
                            <Td>{modelo.titulo_anuncio}
                              {modelo.borrador?
                                <div className="block_ctrl"><strong>(BORRADOR)</strong></div>
                              :''}
                            </Td>
                            <Td>{modelo.tipo_propiedad}</Td>
                            <Td>
                              <CurrencyFormat
                                value={modelo.precio}
                                displayType={'text'}
                                thousandSeparator={true}
                                prefix={'$'}
                                decimalScale={2}
                                fixedDecimalScale={true}
                              />
                            </Td>
                            <Td>{modelo.descripcion}</Td>
                            <Td>
                              <ButtonGroup
                                className="my-2"
                                size="sm"
                              >
                                <Button disabled={disabled} onClick={() => {getSpecsByModelo(modelo.id);setDataActive(modelo);setTipoTerreno(modelo.uso_suelo);toggleModalUpdate();}} variant="outline-primary">
                                  Editar
                                </Button>
                                {JSON.parse(localStorage.getItem('user_data')).type==='ADMINISTRADOR'?
                                  <Button disabled={disabled} onClick={() => {confirmDeleteModelo(modelo.id);}} variant="outline-primary">
                                    Borrar
                                  </Button>
                                :''}
                                <Button disabled={disabled} onClick={() => {setDataActive(modelo);getImagesByModelo(modelo.id);toggleModalImages();}}>
                                  Imagenes
                                </Button>
                              </ButtonGroup>
                            </Td>   
                          </Tr>
                        ))}
                      </Tbody>
                    </Table>
                  </Col>
                    : ''}
                </Row>

          <Pagination className="mt-3" count={lastPage} page={pageSelected} onChange={handleChangePage} />
      </Card.Body>
    </Card>

    <Modal show={modalAdd} onHide={() => setModalAdd(false)} backdrop="static" keyboard={false}>
        <Formik
          initialValues={{
            desarrollo_id: '',
            titulo_anuncio: '',
            clave_interna: '',
            precio: '',
            tipo_propiedad: '',
            descripcion: '',
            mostrar_precio: '',
            no_dormitorios: '',
            no_banos: '',
            no_medios_banos: '',
            no_estacionamientos: '',
            construccion_m2: '',
            terreno_m2: '',
            largo_terreno: '',
            frente_terreno: '',
            no_piso: '',
            total_pisos: '',
            mantenimiento_mensual: '',
            uso_suelo: '',
            estatus: '',
            borrador: false
          }}
          validationSchema={tipoTerreno ? validaTerreno : validaGeneral}
          onSubmit={(values) => {
            console.log('here!!');
            logedServices.addModelo(values, specs)
              .then(response => {
                getModelos(1, searchState, paramState, orderState);
                toggleModalAdd();
              })
              .catch(function (error) {
              }).then(function () {
                setDisabled(false);
              });
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            touched,
            values
          }) => (
            <form onSubmit={handleSubmit}>
              <Modal.Header>Nueva propiedad</Modal.Header>
              <Modal.Body>
                  {desarrollos!==null && desarrollos.data.length>0 ? 
                  <TextField
                    select
                    autoFocus={true}
                    error={Boolean(touched.desarrollo_id && errors.desarrollo_id)}
                    fullWidth
                    helperText={touched.desarrollo_id && errors.desarrollo_id}
                    label="Desarrollo"
                    margin="normal"
                    name="desarrollo_id"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.desarrollo_id}
                    variant="outlined"
                  >
                    {desarrollos.data.map((desarrollo) => (
                      <MenuItem key={desarrollo.id} value={desarrollo.id}>
                        {desarrollo.nombre}
                      </MenuItem>
                    ))}
                  </TextField>
                  : ''}
                  <TextField
                    error={Boolean(touched.clave_interna && errors.clave_interna)}
                    fullWidth
                    helperText={touched.clave_interna && errors.clave_interna}
                    label="Clave interna"
                    margin="normal"
                    name="clave_interna"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.clave_interna}
                    variant="outlined"
                  />
                  <TextField
                    error={Boolean(touched.titulo_anuncio && errors.titulo_anuncio)}
                    fullWidth
                    helperText={touched.titulo_anuncio && errors.titulo_anuncio}
                    label="Titulo"
                    margin="normal"
                    name="titulo_anuncio"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.titulo_anuncio}
                    variant="outlined"
                  />
                  <TextField
                    error={Boolean(touched.precio && errors.precio)}
                    fullWidth
                    helperText={touched.precio && errors.precio}
                    label="Precio"
                    margin="normal"
                    name="precio"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="number"
                    value={values.precio}
                    variant="outlined"
                  />
                  <TextField
                    multiline
                    rows={4}
                    error={Boolean(touched.descripcion && errors.descripcion)}
                    fullWidth
                    helperText={touched.descripcion && errors.descripcion}
                    label="Descripción"
                    margin="normal"
                    name="descripcion"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.descripcion}
                    variant="outlined"
                  />
                  <TextField
                    select
                    error={Boolean(touched.mostrar_precio && errors.mostrar_precio)}
                    fullWidth
                    helperText={touched.mostrar_precio && errors.mostrar_precio}
                    label="Mostrar precio"
                    margin="normal"
                    name="mostrar_precio"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.mostrar_precio}
                    variant="outlined"
                    >
                    <MenuItem key='01' value='1' selected={true}>
                      Si
                    </MenuItem>
                    <MenuItem key='02' value='0'>
                      No
                    </MenuItem>
                  </TextField>
                  <TextField
                    select
                    error={Boolean(touched.tipo_propiedad && errors.tipo_propiedad)}
                    fullWidth
                    helperText={touched.tipo_propiedad && errors.tipo_propiedad}
                    label="Tipo de propiedad"
                    margin="normal"
                    name="tipo_propiedad"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.tipo_propiedad}
                    variant="outlined"
                  >
                    <MenuItem key='01' value='CASA' onClick={() => {setTipoTerreno(false)}}>
                      Casa
                    </MenuItem>
                    <MenuItem key='02' value='DEPARTAMENTO' onClick={() => {setTipoTerreno(false)}}>
                      Departamento
                    </MenuItem>
                    <MenuItem key='04' value='TERRENO' onClick={() => {setTipoTerreno(true)}}>
                      Terreno
                    </MenuItem>
                    <MenuItem key='03' value='OTROS' onClick={() => {setTipoTerreno(true)}}>
                      Otros
                    </MenuItem>
                  </TextField>
                  <TextField
                    style={(tipoTerreno) ? {display: 'none'}: {display: 'block'}}
                    error={Boolean(touched.no_dormitorios && errors.no_dormitorios)}
                    fullWidth
                    helperText={touched.no_dormitorios && errors.no_dormitorios}
                    label="Número de dormitorios"
                    margin="normal"
                    name="no_dormitorios"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="number"
                    value={values.no_dormitorios}
                    variant="outlined"
                  />
                  <TextField
                    style={(tipoTerreno) ? {display: 'none'}: {display: 'block'}}
                    error={Boolean(touched.no_banos && errors.no_banos)}
                    fullWidth
                    helperText={touched.no_banos && errors.no_banos}
                    label="Número de baños"
                    margin="normal"
                    name="no_banos"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="number"
                    value={values.no_banos}
                    variant="outlined"
                  />
                  <TextField
                    style={(tipoTerreno) ? {display: 'none'}: {display: 'block'}}
                    error={Boolean(touched.no_medios_banos && errors.no_medios_banos)}
                    fullWidth
                    helperText={touched.no_medios_banos && errors.no_medios_banos}
                    label="Número de medios baños"
                    margin="normal"
                    name="no_medios_banos"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="number"
                    value={values.no_medios_banos}
                    variant="outlined"
                  />
                  <TextField
                    style={(tipoTerreno) ? {display: 'none'}: {display: 'block'}}
                    error={Boolean(touched.no_estacionamientos && errors.no_estacionamientos)}
                    fullWidth
                    helperText={touched.no_estacionamientos && errors.no_estacionamientos}
                    label="Número de cajones de estacionamiento"
                    margin="normal"
                    name="no_estacionamientos"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="number"
                    value={values.no_estacionamientos}
                    variant="outlined"
                  />
                  <TextField
                    style={(tipoTerreno) ? {display: 'none'}: {display: 'block'}}
                    error={Boolean(touched.construccion_m2 && errors.construccion_m2)}
                    fullWidth
                    helperText={touched.construccion_m2 && errors.construccion_m2}
                    label="Metros cuadrados de construcción"
                    margin="normal"
                    name="construccion_m2"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="number"
                    value={values.construccion_m2}
                    variant="outlined"
                  />
                  <TextField
                    error={Boolean(touched.terreno_m2 && errors.terreno_m2)}
                    fullWidth
                    helperText={touched.terreno_m2 && errors.terreno_m2}
                    label="Metros cuadrados de terreno"
                    margin="normal"
                    name="terreno_m2"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="number"
                    value={values.terreno_m2}
                    variant="outlined"
                  />
                  <TextField
                    style={(!tipoTerreno) ? {display: 'none'}: {display: 'block'}}
                    error={Boolean(touched.largo_terreno && errors.largo_terreno)}
                    fullWidth
                    helperText={touched.largo_terreno && errors.largo_terreno}
                    label="Largo del terreno"
                    margin="normal"
                    name="largo_terreno"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="number"
                    value={values.largo_terreno}
                    variant="outlined"
                  />
                  <TextField
                    style={(!tipoTerreno) ? {display: 'none'}: {display: 'block'}}
                    error={Boolean(touched.frente_terreno && errors.frente_terreno)}
                    fullWidth
                    helperText={touched.frente_terreno && errors.frente_terreno}
                    label="Frente del terreno"
                    margin="normal"
                    name="frente_terreno"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="number"
                    value={values.frente_terreno}
                    variant="outlined"
                  />
                  <TextField
                    style={(tipoTerreno) ? {display: 'none'}: {display: 'block'}}
                    error={Boolean(touched.no_piso && errors.no_piso)}
                    fullWidth
                    helperText={touched.no_piso && errors.no_piso}
                    label="No. piso"
                    margin="normal"
                    name="no_piso"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="number"
                    value={values.no_piso}
                    variant="outlined"
                  />

                  <TextField
                    style={(tipoTerreno) ? {display: 'none'}: {display: 'block'}}
                    error={Boolean(touched.total_pisos && errors.total_pisos)}
                    fullWidth
                    helperText={touched.total_pisos && errors.total_pisos}
                    label="No. de pisos"
                    margin="normal"
                    name="total_pisos"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="number"
                    value={values.total_pisos}
                    variant="outlined"
                  />

                  <TextField
                    style={(tipoTerreno) ? {display: 'none'}: {display: 'block'}}
                    error={Boolean(touched.mantenimiento_mensual && errors.mantenimiento_mensual)}
                    fullWidth
                    helperText={touched.mantenimiento_mensual && errors.mantenimiento_mensual}
                    label="Mantenimiento mensual"
                    margin="normal"
                    name="mantenimiento_mensual"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="number"
                    value={values.mantenimiento_mensual}
                    variant="outlined"
                  />
                  <TextField
                    select
                    style={(tipoTerreno) ? {display: 'block'}: {display: 'none'}}
                    error={Boolean(touched.uso_suelo && errors.uso_suelo)}
                    fullWidth
                    helperText={touched.uso_suelo && errors.uso_suelo}
                    label="Uso de suelo"
                    margin="normal"
                    name="uso_suelo"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.uso_suelo}
                    variant="outlined"
                  >
                    <MenuItem key='01' value='RESIDENCIAL'>
                      Residencial
                    </MenuItem>
                    <MenuItem key='02' value='ACTIVIDADES PRODUCTIVAS'>
                      Actividades Productivas
                    </MenuItem>
                    <MenuItem key='03' value='EQUIPAMIENTO'>
                      Equipamiento
                    </MenuItem>
                    <MenuItem key='04' value='INFRAESTRUCTURA'>
                      Infraestructura
                    </MenuItem>
                    <MenuItem key='05' value='ESPACIO PÚBLICO'>
                      Espacio Público
                    </MenuItem>
                    <MenuItem key='06' value='AREA VERDE'>
                      Area Verde
                    </MenuItem>
                  </TextField>
                  <TextField
                    select
                    error={Boolean(touched.estatus && errors.estatus)}
                    fullWidth
                    helperText={touched.estatus && errors.estatus}
                    label="Estatus"
                    margin="normal"
                    name="estatus"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.estatus}
                    variant="outlined"
                  >
                    <MenuItem key='01' value='PREVENTA'>
                      Preventa
                    </MenuItem>
                    <MenuItem key='02' value='LISTO'>
                      Entrega inmediata
                    </MenuItem>
                    <MenuItem key='03' value='AMBOS'>
                      Ambos
                    </MenuItem>
                  </TextField>
                  <FormControlLabel
                    className="m-0"
                    control={
                      <Checkbox
                        name="borrador"
                        checked={values.borrador}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        inputProps={{ 'aria-label': 'controlled' }}
                        sx={{
                          '&.Mui-checked': {
                            color: red[600],
                          },
                        }}
                      />
                    }
                    label="Borrador"
                    labelPlacement="start"
                  />
                  <React.Fragment>
                    <FormGroup className="pt-3">
                      <span className="block_ctrl">Caracteristicas</span>
                    </FormGroup>
                    <CheckboxSpecs propSpecs={specs}/>
                  </React.Fragment> 
              </Modal.Body>
              <Modal.Footer>
                <Button color="primary" type="submit" disabled={disabled}>
                  Guardar
                </Button>{' '}
                <Button disabled={disabled} color="secondary" onClick={toggleModalAdd}>
                  Cancelar
                </Button>
              </Modal.Footer>
            </form>
          )}
        </Formik>
    </Modal>

    <Modal show={modalUpdate} onHide={() => setModalUpdate(false)} backdrop="static" keyboard={false}>
        <Formik
          initialValues={{
            id: dataActive!==null?dataActive.id:'',
            desarrollo_id: dataActive!==null?dataActive.desarrollo_id:'',
            titulo_anuncio: dataActive!==null?dataActive.titulo_anuncio:'',
            clave_interna: dataActive!==null?dataActive.clave_interna:'',
            precio: dataActive!==null?dataActive.precio:'',
            tipo_propiedad: dataActive!==null?dataActive.tipo_propiedad:'',
            descripcion: dataActive!==null?dataActive.descripcion:'',
            mostrar_precio: dataActive!==null?dataActive.mostrar_precio:'',
            no_dormitorios: dataActive!==null?dataActive.no_dormitorios:'',
            no_banos: dataActive!==null?dataActive.no_banos:'',
            no_medios_banos: dataActive!==null?dataActive.no_medios_banos:'',
            no_estacionamientos: dataActive!==null?dataActive.no_estacionamientos:'',
            construccion_m2: dataActive!==null?dataActive.construccion_m2:'',
            terreno_m2: dataActive!==null?dataActive.terreno_m2:'',
            largo_terreno: dataActive!==null?dataActive.largo_terreno:'',
            frente_terreno: dataActive!==null?dataActive.frente_terreno:'',
            no_piso: dataActive!==null?dataActive.no_piso:'',
            total_pisos: dataActive!==null?dataActive.total_pisos:'',
            mantenimiento_mensual: dataActive!==null?dataActive.mantenimiento_mensual:'',
            uso_suelo: dataActive!==null?dataActive.uso_suelo:'',
            estatus: dataActive!==null?dataActive.estatus:'',
            borrador: dataActive!==null && dataActive.borrador!==null ? dataActive.borrador : ''
          }}
          validationSchema= {tipoTerreno ? validaTerreno : validaGeneral}
          onSubmit={(values) => {
            setDisabled(true);
            logedServices.updateModelo(values, specsByModelo)
              .then(response => {
                getModelos(pageSelected, searchState, paramState, orderState);
                toggleModalUpdate();
              })
              .catch(function (error) {
              }).then(function () {
                setDisabled(false);
              });
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            touched,
            values,
          }) => (
            <form onSubmit={handleSubmit}>
              <Modal.Header>Edición de propiedad</Modal.Header>
              <Modal.Body>
                  {desarrollos!==null && desarrollos.data.length>0 ? 
                  <TextField
                    select
                    autoFocus={true}
                    error={Boolean(touched.desarrollo_id && errors.desarrollo_id)}
                    fullWidth
                    helperText={touched.desarrollo_id && errors.desarrollo_id}
                    label="Desarrollo"
                    margin="normal"
                    name="desarrollo_id"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.desarrollo_id}
                    variant="outlined"
                  >
                    {desarrollos.data.map((desarrollo) => (
                      <MenuItem key={desarrollo.id} value={desarrollo.id}>
                        {desarrollo.nombre}
                      </MenuItem>
                    ))}
                  </TextField>
                  : ''}
                  <TextField
                    error={Boolean(touched.clave_interna && errors.clave_interna)}
                    fullWidth
                    helperText={touched.clave_interna && errors.clave_interna}
                    label="Clave interna"
                    margin="normal"
                    name="clave_interna"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.clave_interna}
                    variant="outlined"
                  />
                  <TextField
                    error={Boolean(touched.titulo_anuncio && errors.titulo_anuncio)}
                    fullWidth
                    helperText={touched.titulo_anuncio && errors.titulo_anuncio}
                    label="Titulo"
                    margin="normal"
                    name="titulo_anuncio"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.titulo_anuncio}
                    variant="outlined"
                  />
                  <TextField
                    error={Boolean(touched.precio && errors.precio)}
                    fullWidth
                    helperText={touched.precio && errors.precio}
                    label="Precio"
                    margin="normal"
                    name="precio"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="number"
                    value={values.precio}
                    variant="outlined"
                  />
                  <TextField
                    multiline
                    rows={4}
                    error={Boolean(touched.descripcion && errors.descripcion)}
                    fullWidth
                    helperText={touched.descripcion && errors.descripcion}
                    label="Descripción"
                    margin="normal"
                    name="descripcion"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.descripcion}
                    variant="outlined"
                  />
                  <TextField
                    select
                    error={Boolean(touched.mostrar_precio && errors.mostrar_precio)}
                    fullWidth
                    helperText={touched.mostrar_precio && errors.mostrar_precio}
                    label="Mostrar precio"
                    margin="normal"
                    name="mostrar_precio"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.mostrar_precio}
                    variant="outlined"
                    >
                    <MenuItem key='01' value='1'>
                      Si
                    </MenuItem>
                    <MenuItem key='02' value='0'>
                      No
                    </MenuItem>
                  </TextField>
                  <TextField
                    select
                    error={Boolean(touched.tipo_propiedad && errors.tipo_propiedad)}
                    fullWidth
                    helperText={touched.tipo_propiedad && errors.tipo_propiedad}
                    label="Tipo de propiedad"
                    margin="normal"
                    name="tipo_propiedad"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.tipo_propiedad}
                    variant="outlined"
                  >
                    <MenuItem key='01' value='CASA' onClick={() => {setTipoTerreno(false)}}>
                      Casa
                    </MenuItem>
                    <MenuItem key='02' value='DEPARTAMENTO' onClick={() => {setTipoTerreno(false)}}>
                      Departamento
                    </MenuItem>
                    <MenuItem key='04' value='TERRENO' onClick={() => {setTipoTerreno(true)}}>
                      Terreno
                    </MenuItem>
                    <MenuItem key='03' value='OTROS' onClick={() => {setTipoTerreno(true)}}>
                      Otros
                    </MenuItem>
                  </TextField>
                  <TextField
                    style={(tipoTerreno) ? {display: 'none'}: {display: 'block'}}
                    error={Boolean(touched.no_dormitorios && errors.no_dormitorios)}
                    fullWidth
                    helperText={touched.no_dormitorios && errors.no_dormitorios}
                    label="Número de dormitorios"
                    margin="normal"
                    name="no_dormitorios"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="number"
                    value={values.no_dormitorios}
                    variant="outlined"
                  />
                  <TextField
                    style={(tipoTerreno) ? {display: 'none'}: {display: 'block'}}
                    error={Boolean(touched.no_banos && errors.no_banos)}
                    fullWidth
                    helperText={touched.no_banos && errors.no_banos}
                    label="Número de baños"
                    margin="normal"
                    name="no_banos"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="number"
                    value={values.no_banos}
                    variant="outlined"
                  />
                  <TextField
                    style={(tipoTerreno) ? {display: 'none'}: {display: 'block'}}
                    error={Boolean(touched.no_medios_banos && errors.no_medios_banos)}
                    fullWidth
                    helperText={touched.no_medios_banos && errors.no_medios_banos}
                    label="Número de medios baños"
                    margin="normal"
                    name="no_medios_banos"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="number"
                    value={values.no_medios_banos}
                    variant="outlined"
                  />
                  <TextField
                    style={(tipoTerreno) ? {display: 'none'}: {display: 'block'}}
                    error={Boolean(touched.no_estacionamientos && errors.no_estacionamientos)}
                    fullWidth
                    helperText={touched.no_estacionamientos && errors.no_estacionamientos}
                    label="Número de cajones de estacionamiento"
                    margin="normal"
                    name="no_estacionamientos"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="number"
                    value={values.no_estacionamientos}
                    variant="outlined"
                  />
                  <TextField
                    style={(tipoTerreno) ? {display: 'none'}: {display: 'block'}}
                    error={Boolean(touched.construccion_m2 && errors.construccion_m2)}
                    fullWidth
                    helperText={touched.construccion_m2 && errors.construccion_m2}
                    label="Metros cuadrados de construcción"
                    margin="normal"
                    name="construccion_m2"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="number"
                    value={values.construccion_m2}
                    variant="outlined"
                  />
                  <TextField
                    error={Boolean(touched.terreno_m2 && errors.terreno_m2)}
                    fullWidth
                    helperText={touched.terreno_m2 && errors.terreno_m2}
                    label="Metros cuadrados de terreno"
                    margin="normal"
                    name="terreno_m2"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="number"
                    value={values.terreno_m2}
                    variant="outlined"
                  />
                  <TextField
                    style={(!tipoTerreno) ? {display: 'none'}: {display: 'block'}}
                    error={Boolean(touched.largo_terreno && errors.largo_terreno)}
                    fullWidth
                    helperText={touched.largo_terreno && errors.largo_terreno}
                    label="Largo del terreno"
                    margin="normal"
                    name="largo_terreno"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="number"
                    value={values.largo_terreno}
                    variant="outlined"
                  />
                  <TextField
                    style={(!tipoTerreno) ? {display: 'none'}: {display: 'block'}}
                    error={Boolean(touched.frente_terreno && errors.frente_terreno)}
                    fullWidth
                    helperText={touched.frente_terreno && errors.frente_terreno}
                    label="Frente del terreno"
                    margin="normal"
                    name="frente_terreno"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="number"
                    value={values.frente_terreno}
                    variant="outlined"
                  />
                  <TextField
                    style={(tipoTerreno) ? {display: 'none'}: {display: 'block'}}
                    error={Boolean(touched.no_piso && errors.no_piso)}
                    fullWidth
                    helperText={touched.no_piso && errors.no_piso}
                    label="No. piso"
                    margin="normal"
                    name="no_piso"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="number"
                    value={values.no_piso}
                    variant="outlined"
                  />

                  <TextField
                    style={(tipoTerreno) ? {display: 'none'}: {display: 'block'}}
                    error={Boolean(touched.total_pisos && errors.total_pisos)}
                    fullWidth
                    helperText={touched.total_pisos && errors.total_pisos}
                    label="No. de pisos"
                    margin="normal"
                    name="total_pisos"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="number"
                    value={values.total_pisos}
                    variant="outlined"
                  />

                  <TextField
                    style={(tipoTerreno) ? {display: 'none'}: {display: 'block'}}
                    error={Boolean(touched.mantenimiento_mensual && errors.mantenimiento_mensual)}
                    fullWidth
                    helperText={touched.mantenimiento_mensual && errors.mantenimiento_mensual}
                    label="Mantenimiento mensual"
                    margin="normal"
                    name="mantenimiento_mensual"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="number"
                    value={values.mantenimiento_mensual}
                    variant="outlined"
                  />
                  <TextField
                    select
                    style={(tipoTerreno) ? {display: 'block'}: {display: 'none'}}
                    error={Boolean(touched.uso_suelo && errors.uso_suelo)}
                    fullWidth
                    helperText={touched.uso_suelo && errors.uso_suelo}
                    label="Uso de suelo"
                    margin="normal"
                    name="uso_suelo"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.uso_suelo}
                    variant="outlined"
                  >
                    <MenuItem key='01' value='RESIDENCIAL'>
                      Residencial
                    </MenuItem>
                    <MenuItem key='02' value='ACTIVIDADES PRODUCTIVAS'>
                      Actividades Productivas
                    </MenuItem>
                    <MenuItem key='03' value='EQUIPAMIENTO'>
                      Equipamiento
                    </MenuItem>
                    <MenuItem key='04' value='INFRAESTRUCTURA'>
                      Infraestructura
                    </MenuItem>
                    <MenuItem key='05' value='ESPACIO PÚBLICO'>
                      Espacio Público
                    </MenuItem>
                    <MenuItem key='06' value='AREA VERDE'>
                      Area Verde
                    </MenuItem>
                  </TextField>
                  <TextField
                    select
                    error={Boolean(touched.estatus && errors.estatus)}
                    fullWidth
                    helperText={touched.estatus && errors.estatus}
                    label="Estatus"
                    margin="normal"
                    name="estatus"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.estatus}
                    variant="outlined"
                  >
                    <MenuItem value='PREVENTA'>
                      Preventa
                    </MenuItem>
                    <MenuItem value='LISTO'>
                      Entrega inmediata
                    </MenuItem>
                    <MenuItem value='AMBOS'>
                      Ambos
                    </MenuItem>
                  </TextField>
                  <FormControlLabel
                    className="m-0"
                    control={
                      <Checkbox
                        name="borrador"
                        checked={values.borrador}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        inputProps={{ 'aria-label': 'controlled' }}
                        sx={{
                          '&.Mui-checked': {
                            color: red[600],
                          },
                        }}
                      />
                    }
                    label="Borrador"
                    labelPlacement="start"
                  />
                  <React.Fragment>
                    <FormGroup className="pt-3">
                      <span className="block_ctrl">Caracteristicas</span>
                    </FormGroup>
                    <CheckboxSpecsUpd propSpecs={specsByModelo}/>
                  </React.Fragment> 
              </Modal.Body>
              <Modal.Footer>
                <Button color="primary" type="submit" disabled={disabled}>
                  Guardar
                </Button>{' '}
                <Button disabled={disabled} color="secondary" onClick={toggleModalUpdate}>
                  Cancelar
                </Button>
              </Modal.Footer>
            </form>
          )}
        </Formik>
    </Modal>

    <Modal show={modalAmenidades} onHide={() => setModalAmenidades(false)} backdrop="static" keyboard={false}>
      <Modal.Header>Amenidades</Modal.Header>
      <Modal.Body>
        <Grid container spacing={2} justifyContent="center" alignItems="center">
          <Grid item>{customList(left)}</Grid>
          <Grid item>
            <Grid container direction="column" alignItems="center">
              <ButtonMaterial
                sx={{ my: 0.5 }}
                variant="outlined"
                size="small"
                onClick={handleAllRight}
                disabled={left===null || left.length === 0}
                aria-label="move all right"
              >
                ≫
              </ButtonMaterial>
              <ButtonMaterial
                sx={{ my: 0.5 }}
                variant="outlined"
                size="small"
                onClick={handleCheckedRight}
                disabled={leftChecked.length === 0}
                aria-label="move selected right"
              >
                &gt;
              </ButtonMaterial>
              <ButtonMaterial
                sx={{ my: 0.5 }}
                variant="outlined"
                size="small"
                onClick={handleCheckedLeft}
                disabled={rightChecked.length === 0}
                aria-label="move selected left"
              >
                &lt;
              </ButtonMaterial>
              <ButtonMaterial
                sx={{ my: 0.5 }}
                variant="outlined"
                size="small"
                onClick={handleAllLeft}
                disabled={right===null || right.length === 0}
                aria-label="move all left"
              >
                ≪
              </ButtonMaterial>
            </Grid>
          </Grid>
          <Grid item>{customList(right)}</Grid>
        </Grid>
      </Modal.Body>
      <Modal.Footer>
        <Button color="primary" type="button" onClick={saveAmenidadesPropiedad} disabled={disabled}>
          Guardar
        </Button>{' '}
        <Button disabled={disabled} color="secondary" onClick={toggleModalAmenidades}>
          Cancelar
        </Button>
      </Modal.Footer>
    </Modal>

    <Modal show={modalImages} onHide={() => setModalImages(false)} backdrop="static" keyboard={false}>
      <Modal.Header>Imagenes</Modal.Header>
      <Modal.Body>
        {imagenes!==null && imagenes.length>0 ?
          <React.Fragment> 
            <div className="block_ctrl">
              <span className="h4">Cargadas</span>
            </div>
            <div className="block_ctrl pb-4">
              <div className="row justify-content-center align-items-center">
                <SortableList
                  onSortEnd={onSortEnd}
                  className="list_sort"
                  draggedItemClassName="dragged_s"
                >
                  {imagenes.map((item) => (
                    <SortableItem key={item.id}>
                      <div className="item">
                        <div className="block_ctrl">
                          <img
                            alt={item.imagen.nombre}
                            className="block_ctrl csr_image"
                            src={`${process.env.REACT_APP_API_MAIN+item.imagen.nombre}?w=248&fit=crop&auto=format`}
                            srcSet={`${process.env.REACT_APP_API_MAIN+item.imagen.nombre}?w=248&fit=crop&auto=format&dpr=2 2x`}
                            loading="lazy"
                          />
                          <div className="block_ctrl pt-1 text-center">
                            <ButtonMaterial variant="outlined" startIcon={<DeleteIcon />}  onClick={() => onImageRemove(item.id)}>
                              Borrar
                            </ButtonMaterial>
                          </div>
                        </div>
                      </div>
                    </SortableItem>
                  ))}
                </SortableList>
              </div>
            </div>
          </React.Fragment> 
        :''}
        <div className="block_ctrl">
          <span className="h4">Por cargar</span>
        </div>
        <ImageUploading
          multiple
          value={images}
          onChange={onChange}
          maxNumber={maxNumberImg}
          maxFileSize={209715}
          acceptType={['jpg', 'jpeg', 'png', 'svg']}
          resolutionType={'more'}
          resolutionWidth={500}
          resolutionHeight={500}
          dataURLKey="data_url"
        >
          {({
            imageList,
            onImageUpload,
            onImageRemoveAll,
            onImageUpdate,
            onImageRemove,
            isDragging,
            dragProps,
            errors
          }) => (
            // write your building UI
            <div className="upload__image-wrapper">
              <ButtonMaterial
                variant="contained"
                endIcon={<SendIcon />}
                style={isDragging ? { color: 'red' } : undefined}
                onClick={onImageUpload}
                disabled={maxNumberImg===0}
                {...dragProps}
              >
                Carga hasta {maxNumberImg>1?maxNumberImg+' imágenes':maxNumberImg+' imagen'} 
              </ButtonMaterial>
              &nbsp;
              <IconButton onClick={onImageRemoveAll} color="primary" aria-label="add to shopping cart">
                <DeleteIcon />
              </IconButton>
              <div className="block_ctrl pt-2">
                {imageList.map((image, index) => (
                  <div key={index} className="image-item">
                    <img src={image['data_url']} alt="" width="100" />
                    <div className="image-item__btn-wrapper">
                      <ButtonMaterial
                        variant="outlined"
                        startIcon={<FilterIcon />}
                        onClick={() => onImageUpdate(index)}
                        size="small"
                      >Reemplazar</ButtonMaterial>
                      <ButtonMaterial
                        variant="outlined"
                        startIcon={<DeleteIcon />}
                        onClick={() => onImageRemove(index)}
                        size="small"
                      >Borrar</ButtonMaterial>
                    </div>
                  </div>
                ))}
              </div>
              {errors?
                <div className="block_ctrl pt-2">
                  {errors.maxNumber && <span>Puedes seleccionar hasta {maxNumberImg} imágenes.</span>}
                  {errors.acceptType && <span>Las extensiones permitidas son JPEG, JPG, PNG y SVG.</span>}
                  {errors.maxFileSize && <span>La imagen excede el peso permitido de 200Kb.</span>}
                  {errors.resolution && <span>La imagen es demasiado pequeña.</span>}
                </div>
              :''}
            </div>
          )}
        </ImageUploading>

      </Modal.Body>
      <Modal.Footer>
        <Button color="primary" type="button" onClick={saveImagesModelo} disabled={disabled}>
          Guardar
        </Button>{' '}
        <Button disabled={disabled} color="secondary" onClick={toggleModalImages}>
          Cancelar
        </Button>
      </Modal.Footer>
    </Modal>

    <Backdrop
      sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={disabled}
    >
      <CircularProgress color="inherit" />
    </Backdrop>

  </React.Fragment> 
  );
};

const Propiedades = () => (
  <Container fluid className="p-0">
    <h1 className="h3 mb-3">Propiedades</h1>
    <TableRes />
  </Container>
);

export default Propiedades;