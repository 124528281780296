import React from "react";

// All pages that rely on 3rd party components (other than Bootstrap) are
// loaded asynchronously, to keep the initial JS bundle to a minimum size

// Layouts
import AuthLayout from "./layouts/Auth";
import DashboardLayout from "./layouts/Dashboard";
import DocLayout from "./layouts/Doc";

// Guards
import AuthGuard from "./components/guards/AuthGuard";

// Pages
import Profile from "./pages/pages/Profile";
import Settings from "./pages/pages/Settings";
import Clients from "./pages/pages/Clients";
import Projects from "./pages/pages/Projects";
import Invoice from "./pages/pages/Invoice";
import Pricing from "./pages/pages/Pricing";
import Tasks from "./pages/pages/Tasks";
import Chat from "./pages/pages/Chat";
import Blank from "./pages/pages/Blank";

// Auth
import Page500 from "./pages/auth/Page500";
import Page404 from "./pages/auth/Page404";
import SignIn from "./pages/auth/SignIn";
import SignUp from "./pages/auth/SignUp";
import ResetPassword from "./pages/auth/ResetPassword";
import MakePassword from "./pages/auth/MakePassword";

// UI components
import Alerts from "./pages/ui/Alerts";
import Buttons from "./pages/ui/Buttons";
import Cards from "./pages/ui/Cards";
import Carousel from "./pages/ui/Carousel";
import EmbedVideo from "./pages/ui/EmbedVideo";
import General from "./pages/ui/General";
import Grid from "./pages/ui/Grid";
import Modals from "./pages/ui/Modals";
import Offcanvas from "./pages/ui/Offcanvas";
import Tabs from "./pages/ui/Tabs";
import Typography from "./pages/ui/Typography";

// Icons
import Feather from "./pages/icons/Feather";
import FontAwesome from "./pages/icons/FontAwesome";

// Forms
import Layouts from "./pages/forms/Layouts";
import BasicInputs from "./pages/forms/BasicInputs";
import InputGroups from "./pages/forms/InputGroups";
import FloatingLabels from "./pages/forms/FloatingLabels";
import AdvancedInputs from "./pages/forms/AdvancedInputs";
import Formik from "./pages/forms/Formik";
import Editors from "./pages/forms/Editors";

// Tables
import Tables from "./pages/tables/Tables";
import Pagination from "./pages/tables/Pagination";
import ColumnSorting from "./pages/tables/ColumnSorting";
import ColumnFiltering from "./pages/tables/ColumnFiltering";
import RowExpanding from "./pages/tables/RowExpanding";
import RowSelection from "./pages/tables/RowSelection";

// Charts
import Chartjs from "./pages/charts/Chartjs";
import ApexCharts from "./pages/charts/ApexCharts";

// Notifications
import Notifications from "./pages/notifications/Notifications";

// Maps
import GoogleMaps from "./pages/maps/GoogleMaps";
import VectorMaps from "./pages/maps/VectorMaps";

// Calendar
import Calendar from "./pages/calendar/Calendar";

// Documentation
import Introduction from "./pages/docs/Introduction";
import GettingStarted from "./pages/docs/GettingStarted";
import Routing from "./pages/docs/Routing";
import Auth0 from "./pages/docs/auth/Auth0";
import Cognito from "./pages/docs/auth/Cognito";
import Firebase from "./pages/docs/auth/Firebase";
import JWT from "./pages/docs/auth/JWT";
import Guards from "./pages/docs/Guards";
import APICalls from "./pages/docs/APICalls";
import Redux from "./pages/docs/Redux";
import Internationalization from "./pages/docs/Internationalization";
import EnvironmentVariables from "./pages/docs/EnvironmentVariables";
import ESLintAndPrettier from "./pages/docs/ESLintAndPrettier";
import Deployment from "./pages/docs/Deployment";
import MigratingToNextJS from "./pages/docs/MigratingToNextJS";
import Support from "./pages/docs/Support";
import Changelog from "./pages/docs/Changelog";

// Protected routes
import ProtectedPage from "./pages/protected/ProtectedPage";

import Propiedades from "./pages/propiedades";
import Desarrollos from "./pages/desarrollos";
import Amenidades from "./pages/amenidades";
import Caracteristicas from "./pages/caracteristicas";
import Usuarios from "./pages/usuarios";
import Notificaciones from "./pages/notificaciones";

const routes = [
  {
    path: "/",
    element: <AuthLayout />,
    children: [
      {
        path: "",
        element: <SignIn />
      },
      {
        path: "login",
        element: <SignIn />
      },
      {
        path: "reestablecer-contrasena",
        element: <ResetPassword />
      },
      {
        path: "reestablecer-contrasena/:code/code",
        element: <MakePassword />
      },
    ],
  },
  {
    path: "admin",
    element: <DashboardLayout />,
    children: [
      {
        path: "desarrollos",
        element: <Desarrollos />,
      },
      {
        path: "propiedades",
        element: <Propiedades />,
      },
      {
        path: "amenidades",
        element: <Amenidades />,
      },
      {
        path: "caracteristicas",
        element: <Caracteristicas />,
      },
      {
        path: "usuarios",
        element: <Usuarios />,
      },
      {
        path: "notificaciones",
        element: <Notificaciones />,
      },
      {
        path: "perfil",
        element: <Settings />,
      },
    ],
  },
  // {
  //   path: "pages",
  //   element: <DashboardLayout />,
  //   children: [
  //     {
  //       path: "profile",
  //       element: <Profile />,
  //     },
  //     {
  //       path: "settings",
  //       element: <Settings />,
  //     },
  //     {
  //       path: "clients",
  //       element: <Clients />,
  //     },
  //     {
  //       path: "projects",
  //       element: <Projects />,
  //     },
  //     {
  //       path: "invoice",
  //       element: <Invoice />,
  //     },
  //     {
  //       path: "pricing",
  //       element: <Pricing />,
  //     },
  //     {
  //       path: "tasks",
  //       element: <Tasks />,
  //     },
  //     {
  //       path: "chat",
  //       element: <Chat />,
  //     },
  //     {
  //       path: "blank",
  //       element: <Blank />,
  //     },
  //   ],
  // },
  // {
  //   path: "auth",
  //   element: <AuthLayout />,
  //   children: [
  //     {
  //       path: "login",
  //       element: <SignIn />,
  //     },
  //     {
  //       path: "sign-up",
  //       element: <SignUp />,
  //     },
  //     {
  //       path: "reestablecer-contrasena/:code/code",
  //       element: <MakePassword />,
  //     },
  //     {
  //       path: "reestablecer-contrasena",
  //       element: <ResetPassword />,
  //     },
  //     {
  //       path: "404",
  //       element: <Page404 />,
  //     },
  //     {
  //       path: "500",
  //       element: <Page500 />,
  //     },
  //   ],
  // },
  // {
  //   path: "ui",
  //   element: <DashboardLayout />,
  //   children: [
  //     {
  //       path: "alerts",
  //       element: <Alerts />,
  //     },
  //     {
  //       path: "buttons",
  //       element: <Buttons />,
  //     },
  //     {
  //       path: "cards",
  //       element: <Cards />,
  //     },
  //     {
  //       path: "carousel",
  //       element: <Carousel />,
  //     },
  //     {
  //       path: "embed-video",
  //       element: <EmbedVideo />,
  //     },
  //     {
  //       path: "general",
  //       element: <General />,
  //     },
  //     {
  //       path: "grid",
  //       element: <Grid />,
  //     },
  //     {
  //       path: "modals",
  //       element: <Modals />,
  //     },
  //     {
  //       path: "tabs",
  //       element: <Tabs />,
  //     },
  //     {
  //       path: "offcanvas",
  //       element: <Offcanvas />,
  //     },
  //     {
  //       path: "typography",
  //       element: <Typography />,
  //     },
  //   ],
  // },
  // {
  //   path: "icons",
  //   element: <DashboardLayout />,
  //   children: [
  //     {
  //       path: "feather",
  //       element: <Feather />,
  //     },
  //     {
  //       path: "font-awesome",
  //       element: <FontAwesome />,
  //     },
  //   ],
  // },
  // {
  //   path: "forms",
  //   element: <DashboardLayout />,
  //   children: [
  //     {
  //       path: "layouts",
  //       element: <Layouts />,
  //     },
  //     {
  //       path: "basic-inputs",
  //       element: <BasicInputs />,
  //     },
  //     {
  //       path: "input-groups",
  //       element: <InputGroups />,
  //     },
  //     {
  //       path: "floating-labels",
  //       element: <FloatingLabels />,
  //     },
  //   ],
  // },
  // {
  //   path: "tables",
  //   element: <DashboardLayout />,
  //   children: [
  //     {
  //       path: "",
  //       element: <Tables />,
  //     },
  //   ],
  // },
  // {
  //   path: "form-plugins",
  //   element: <DashboardLayout />,
  //   children: [
  //     {
  //       path: "advanced-inputs",
  //       element: <AdvancedInputs />,
  //     },
  //     {
  //       path: "formik",
  //       element: <Formik />,
  //     },
  //     {
  //       path: "editors",
  //       element: <Editors />,
  //     },
  //   ],
  // },
  // {
  //   path: "advanced-tables",
  //   element: <DashboardLayout />,
  //   children: [
  //     {
  //       path: "pagination",
  //       element: <Pagination />,
  //     },
  //     {
  //       path: "column-sorting",
  //       element: <ColumnSorting />,
  //     },
  //     {
  //       path: "column-filtering",
  //       element: <ColumnFiltering />,
  //     },
  //     {
  //       path: "row-expanding",
  //       element: <RowExpanding />,
  //     },
  //     {
  //       path: "row-selection",
  //       element: <RowSelection />,
  //     },
  //   ],
  // },
  // {
  //   path: "charts",
  //   element: <DashboardLayout />,
  //   children: [
  //     {
  //       path: "chartjs",
  //       element: <Chartjs />,
  //     },
  //     {
  //       path: "apexcharts",
  //       element: <ApexCharts />,
  //     },
  //   ],
  // },
  // {
  //   path: "notifications",
  //   element: <DashboardLayout />,
  //   children: [
  //     {
  //       path: "",
  //       element: <Notifications />,
  //     },
  //   ],
  // },
  // {
  //   path: "maps",
  //   element: <DashboardLayout />,
  //   children: [
  //     {
  //       path: "google-maps",
  //       element: <GoogleMaps />,
  //     },
  //     {
  //       path: "vector-maps",
  //       element: <VectorMaps />,
  //     },
  //   ],
  // },
  // {
  //   path: "calendar",
  //   element: <DashboardLayout />,
  //   children: [
  //     {
  //       path: "",
  //       element: <Calendar />,
  //     },
  //   ],
  // },
  // {
  //   path: "docs",
  //   element: <DocLayout />,
  //   children: [
  //     {
  //       path: "introduction",
  //       element: <Introduction />,
  //     },
  //     {
  //       path: "getting-started",
  //       element: <GettingStarted />,
  //     },
  //     {
  //       path: "routing",
  //       element: <Routing />,
  //     },
  //     {
  //       path: "auth/auth0",
  //       element: <Auth0 />,
  //     },
  //     {
  //       path: "auth/cognito",
  //       element: <Cognito />,
  //     },
  //     {
  //       path: "auth/firebase",
  //       element: <Firebase />,
  //     },
  //     {
  //       path: "auth/jwt",
  //       element: <JWT />,
  //     },
  //     {
  //       path: "guards",
  //       element: <Guards />,
  //     },
  //     {
  //       path: "api-calls",
  //       element: <APICalls />,
  //     },
  //     {
  //       path: "redux",
  //       element: <Redux />,
  //     },
  //     {
  //       path: "internationalization",
  //       element: <Internationalization />,
  //     },
  //     {
  //       path: "environment-variables",
  //       element: <EnvironmentVariables />,
  //     },
  //     {
  //       path: "eslint-and-prettier",
  //       element: <ESLintAndPrettier />,
  //     },
  //     {
  //       path: "deployment",
  //       element: <Deployment />,
  //     },
  //     {
  //       path: "migrating-to-next-js",
  //       element: <MigratingToNextJS />,
  //     },
  //     {
  //       path: "support",
  //       element: <Support />,
  //     },
  //     {
  //       path: "changelog",
  //       element: <Changelog />,
  //     },
  //   ],
  // },
  // {
  //   path: "private",
  //   element: (
  //     <AuthGuard>
  //       <DashboardLayout />
  //     </AuthGuard>
  //   ),
  //   children: [
  //     {
  //       path: "",
  //       element: <ProtectedPage />,
  //     },
  //   ],
  // },
  {
    path: "*",
    element: <AuthLayout />,
    children: [
      {
        path: "*",
        element: <Page404 />,
      },
    ],
  },
];

export default routes;
