import React, { useState, useEffect } from 'react';
import { 
  Card,
  Container,
  Col,
  InputGroup,
  Button,
  ButtonGroup,
  Form,
  Row,
  Modal,
  FormGroup
} from "react-bootstrap";
import ButtonMaterial from '@mui/material/Button';
import Pagination from '@mui/material/Pagination';
import TextField from '@mui/material/TextField';
import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/Add';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table'
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import * as Yup from 'yup';
import { setLocale } from 'yup';
import { Formik } from 'formik';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import noImage from "../../assets/img/no_image.svg";

import { logedServices } from '../../services/logedServices';

export function checkIfFilesAreTooBig(file): boolean {
  let valid = true
  if (file) {
    const size = file.size / 1024 / 1024
    if (size > 10) {
      valid = false
    }
  }
  return valid
}

export function checkIfFilesAreCorrectType(file): boolean {
  let valid = true
  if (file) {
    if (!['application/pdf', 'image/jpeg', 'image/png'].includes(file.type)) {
      valid = false
    }
  }
  return valid
}

const TableRes = () => {

  setLocale({
    mixed: {
      required: 'Este campos es requerido.'
    }
  })
  /*
  setLocale({
    mixed: {
      required: '${path} is requerido'
    }
  })
  */

  const confirmar = (amenidad) => {
    confirmAlert({
      title: 'Confirmación',
      message: 'Estás seguro que deseas eliminar el registro?',
      buttons: [
        {
          label: 'Si',
          onClick: () => {
            deleteAmenidad(amenidad)
          }
        },
        {
          label: 'No',
          onClick: () => {}
        }
      ]
    });
  };


  const [lastPage, setLastPage] = useState(0);
  const [pageSelected, setPageSelected] = useState(0);
  const [searchState, setSearchState] = useState(null);
  const [paramState, setParamState] = useState('id');
  const [orderState, setOrderState] = useState('ASC');
  const [amenidades, setAmenidades] = useState(null);
  const [disabled, setDisabled] = useState(false);
  const [dataActive, setDataActive] = useState(null);
  const [modalAdd, setModalAdd] = useState(false);
  const toggleModalAdd = () => setModalAdd(!modalAdd);
  const [modalUpdate, setModalUpdate] = useState(false);
  const toggleModalUpdate = () => setModalUpdate(!modalUpdate);

  useEffect(() => {
      getAmenidades(1,searchState,paramState,orderState);
  }, []);

  function getAmenidades(page, search, param, order){
      setDisabled(true);
      logedServices.getAmenidades(page, 10, search, param, order)
      .then(response => {
        if (response.status===200) {
          setPageSelected(page);
          setAmenidades(response.data);
          setLastPage(response.data.meta.last_page);
          setSearchState(search);
          setParamState(param);
          setOrderState(order);
        }
        setDisabled(false);
      })
      .catch(function (error) {
          return error;
      })
  }

  function deleteAmenidad(amenidad){
      setDisabled(true);
      logedServices.deleteAmenidad(amenidad.id)
      .then(response => {
        if (response.status===204) {
          getAmenidades(pageSelected, searchState, paramState, orderState);
        }
        setDisabled(false);
      })
      .catch(function (error) {
          return error;
      })
  }

  const handleChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
    getAmenidades(value, searchState, paramState, orderState);
  };

  const handleChangeParam = event => {
    let param_ = event.target.value;
    setParamState(param_);
    getAmenidades(pageSelected, searchState, param_, orderState);
  };

  const handleChangeOrder = event => {
    let order_ = event.target.value;
    setOrderState(order_);
    getAmenidades(pageSelected, searchState, paramState, order_);
  };

  return (
  <React.Fragment> 
    <Card>
      <Card.Body>
          
            
                <Row>
                  {amenidades!==null && amenidades.data.length>0 ? 
                  <React.Fragment>
                    <Col xs="12" md="6" lg="4" xl="3" className="mb-3">
                      <Formik
                        initialValues={{
                          busqueda: ''
                        }}
                        onSubmit={(values) => {
                          getAmenidades(1, values.busqueda, paramState, orderState);
                        }}
                      >
                        {({
                          errors,
                          handleBlur,
                          handleChange,
                          handleSubmit,
                          touched,
                          values
                        }) => (
                          <form onSubmit={handleSubmit}>
                            <Form.Label
                              className="visually-hidden"
                              htmlFor="busqueda"
                            >
                              Busqueda
                            </Form.Label>
                            <InputGroup>
                              <Form.Control 
                                id="busqueda"
                                name="busqueda"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.busqueda}
                              />
                              <Button type="submit" disabled={disabled} color="primary">
                                <SearchIcon />
                              </Button>
                            </InputGroup>
                          </form>
                        )}
                      </Formik>
                    </Col>
                    <Col xs="12" md="6" lg="4" xl="3" className="mb-3">
                      <Form.Label
                        className="visually-hidden"
                        htmlFor="campo"
                      >
                        Orden
                      </Form.Label>
                        <Row>
                          <Col>
                            <Form.Select
                              value={paramState}
                              onChange={handleChangeParam}
                            >
                              <option value="id">
                                ID
                              </option>
                              <option value="nombre">
                                Nombre
                              </option>
                            </Form.Select>
                          </Col>
                          <Col>
                            <Form.Select
                              value={orderState}
                              onChange={handleChangeOrder}
                            >
                              <option value="asc">
                                ASC
                              </option>
                              <option value="desc">
                                DESC
                              </option>
                            </Form.Select>
                          </Col>
                        </Row>
                    </Col>
                  </React.Fragment> 
                  : ''}
                  <Col xs="12" md="6" lg="4" xl="6" className="mb-3 text-end">                  
                    <ButtonMaterial disabled={disabled} variant="contained" endIcon={<AddIcon />} onClick={toggleModalAdd}>
                      Agregar
                    </ButtonMaterial>
                  </Col>
                  {amenidades!==null && amenidades.data.length>0 ? 
                  <Col xs="12" className="mb-3">
                    <Table className="">
                      <Thead>
                        <Tr>
                          <Th>ID</Th>
                          <Th>NOMBRE</Th>
                          <Th>ICONO</Th>
                          <Th></Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {amenidades.data.map((amenidad) => (
                          <Tr key={amenidad.id}>
                            <Td>{amenidad.id}</Td>
                            <Td>{amenidad.nombre}</Td>
                            <Td>
                              <img style={{
                                maxWidth: 120,
                                maxHeight: 120
                              }} src={process.env.REACT_APP_API_MAIN+amenidad.icono} alt={amenidad.nombre}/>
                            </Td>
                            <Td>
                              <ButtonGroup
                                className="my-2"
                                size="sm"
                              >
                                <Button disabled={disabled} onClick={() => {setDataActive(amenidad);toggleModalUpdate();}} variant="outline-primary">
                                  Editar
                                </Button>
                                {JSON.parse(localStorage.getItem('user_data')).type==='ADMINISTRADOR'?
                                  <Button disabled={disabled} onClick={() => {setDataActive(amenidad);confirmar(amenidad);}} variant="outline-primary">
                                    Borrar
                                  </Button>
                                :''}
                              </ButtonGroup>
                            </Td>   
                          </Tr>
                        ))}
                      </Tbody>
                    </Table>
                  </Col>
                  : ''}
                </Row>
            
          <Pagination className="mt-3" count={lastPage} page={pageSelected} onChange={handleChangePage} />
      </Card.Body>
    </Card>

    <Modal show={modalAdd} onHide={() => setModalAdd(false)} backdrop="static" keyboard={false}>
        <Formik
          initialValues={{
            nombre: '',
            icono: null
          }}
          validationSchema={Yup.object().shape({
            nombre: Yup.string().required(),
            icono: Yup.mixed()
              .required()
              .test("fileSize", "La imagen excede el peso permitido de 20Kb.", (value) => {
                  return value && value.size <= 21000;
              })
              .test("type", "Solo se aceptan los siguientes formatos: .jpeg, .jpg, .png", (value) => {
                  return value && (
                      value.type === "image/svg+xml" ||
                      value.type === "image/png"
                  );
              })
          })}
          onSubmit={(values) => {
            setDisabled(true);
            logedServices.addAmenidad(values)
              .then(response => {
                getAmenidades(1, searchState, paramState, orderState);
                toggleModalAdd();
              })
              .catch(function (error) {
              }).then(function () {
                setDisabled(false);
              });
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            touched,
            values,
            setFieldValue
          }) => (
            <form onSubmit={handleSubmit}>
              <Modal.Header>Nueva amenidad</Modal.Header>
              <Modal.Body>
                  <TextField
                    error={Boolean(touched.nombre && errors.nombre)}
                    fullWidth
                    helperText={touched.nombre && errors.nombre}
                    label="Nombre"
                    margin="normal"
                    name="nombre"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.nombre}
                    variant="outlined"
                  />
                  {values.icono!==null && values.icono!==undefined && values.icono!=='' ?
                    <img 
                      alt="Imagen por subir"
                      style={{
                        maxWidth: 120,
                        maxHeight: 120
                      }}
                      src={URL.createObjectURL(values.icono)}
                    />
                  :''}
                  <FormGroup>
                    <Form.Label htmlFor="icono">
                      Icono
                    </Form.Label>
                    <Form.Control
                      isInvalid={Boolean(touched.icono && errors.icono)}
                      id="icono"
                      name="icono"
                      type="file"
                      size="lg"
                      onBlur={handleBlur}
                      onChange={(event) => {
                        setFieldValue("icono", event.currentTarget.files[0]);
                      }}
                    />
                    <Form.Control.Feedback type="invalid">{touched.icono && errors.icono}</Form.Control.Feedback>
                  </FormGroup>
              </Modal.Body>
              <Modal.Footer>
                <Button color="primary" type="submit" disabled={disabled}>
                  Guardar
                </Button>{' '}
                <Button disabled={disabled} color="secondary" onClick={toggleModalAdd}>
                  Cancelar
                </Button>
              </Modal.Footer>
            </form>
          )}
        </Formik>
    </Modal>

    <Modal show={modalUpdate} onHide={() => setModalUpdate(false)} backdrop="static" keyboard={false}>
        <Formik
          initialValues={{
            id: dataActive!==null&&dataActive.id!==null?dataActive.id:'',
            nombre: dataActive!==null&&dataActive.nombre!==null?dataActive.nombre:'',
            icono_show: dataActive!==null&&dataActive.icono!==null?dataActive.icono:'',
            icono: ''
          }}
          validationSchema={Yup.object().shape({
            nombre: Yup.string().required(),
            icono: Yup.mixed()
              .test("fileSize", "La imagen excede el peso permitido de 20Kb.", (value) => {
                if (value!==undefined) {
                  return value.size <= 21000;
                }
                return true;
              })
              .test("type", "Solo se aceptan los siguientes formatos: .svg, .png", (value) => {
                  if (value!==undefined) {
                    return value && (
                        value.type === "image/svg+xml" ||
                        value.type === "image/png"
                    );
                  }
                  return true;
            }),
          })}
          onSubmit={(values) => {
            setDisabled(true);
            logedServices.updateAmenidad(values)
              .then(response => {
                getAmenidades(pageSelected, searchState, paramState, orderState);
                toggleModalUpdate();
              })
              .catch(function (error) {
              }).then(function () {
                setDisabled(false);
              });
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            touched,
            values,
            setFieldValue
          }) => (
            <form onSubmit={handleSubmit}>
              <Modal.Header>Edición de amenidad</Modal.Header>
              <Modal.Body>
                  <TextField
                    error={Boolean(touched.nombre && errors.nombre)}
                    fullWidth
                    helperText={touched.nombre && errors.nombre}
                    label="Nombre"
                    margin="normal"
                    name="nombre"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.nombre}
                    variant="outlined"
                  />
                  {values.icono_show!=='' ?
                    <img 
                      alt={values.nombre}
                      style={{
                        maxWidth: 120,
                        maxHeight: 120
                      }}
                      src={values.icono!==null && values.icono!==undefined && values.icono!==''?URL.createObjectURL(values.icono):process.env.REACT_APP_API_MAIN+values.icono_show}
                    />
                  :
                    <img 
                      alt={values.nombre}
                      style={{
                        maxWidth: 120,
                        maxHeight: 120
                      }}
                      src={noImage}
                    />
                  }
                  <FormGroup>
                    <Form.Label htmlFor="icono">
                      Icono
                    </Form.Label>
                    <Form.Control
                      isInvalid={Boolean(touched.icono && errors.icono)}
                      id="icono"
                      name="icono"
                      type="file"
                      size="lg"
                      onBlur={handleBlur}
                      onChange={(event) => {
                        setFieldValue("icono", event.currentTarget.files[0]);
                      }}
                    />
                    <Form.Control.Feedback type="invalid">{touched.icono && errors.icono}</Form.Control.Feedback>
                  </FormGroup>
              </Modal.Body>
              <Modal.Footer>
                <Button color="primary" type="submit" disabled={disabled}>
                  Guardar
                </Button>{' '}
                <Button disabled={disabled} color="secondary" onClick={toggleModalUpdate}>
                  Cancelar
                </Button>
              </Modal.Footer>
            </form>
          )}
        </Formik>
    </Modal>

    <Backdrop
      sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={disabled}
    >
      <CircularProgress color="inherit" />
    </Backdrop>

  </React.Fragment> 
  );
};

const Amenidades = () => (
  <Container fluid className="p-0">
    <h1 className="h3 mb-3">Amenidades</h1>
    <TableRes />
  </Container>
);

export default Amenidades;